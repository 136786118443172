<template>
  <div>
    <p v-if="fetching">Loading...</p>
    <template v-else>
      <Status class="s-text-secondary s-mb-6" icon="check" message="下單成功！" />
      <div class="s-mb-3 s-space-y-3 s-text-center s-text-secondary">
        <p>您已可至門市取貨<br>結帳依門市實際價格為準</p>
      </div>
      <p class="s-text-center s-text-gray s-mb-10">團購 ID：{{ groupBuying.groupbuying_number }}</p>
      <div class="s-rounded-lg product-card s-mb-8">
        <h2 class="s-font-bold s-text-xl s-mb-4">{{groupBuying.groupbuying_product.title}}</h2>
        <table>
          <tr>
            <td>取貨方式</td>
            <td>門市取貨付款</td>
          </tr>
          <tr>
            <td>取貨期限</td>
            <td>{{ endAt }}</td>
          </tr>
          <tr>
            <td style="width: 50%">原價</td>
            <td>{{ groupBuying.groupbuying_sku.original_price }} 元</td>
          </tr>
          <tr>
            <td>售價</td>
            <td v-if="groupBuying.groupbuying_sku.is_promotion">{{ groupBuying.groupbuying_sku.package_type }}</td>
            <td v-else>{{ groupBuying.groupbuying_sku.price }} 元</td>
          </tr>
          <tr>
            <td>下單數量</td>
            <td class="s-text-secondary">{{ orderQuantity }}</td>
          </tr>
          <tr>
            <td>應付金額</td>
            <td class="s-text-secondary">{{ paidPrice }} 元</td>
          </tr>
        </table>
      </div>
      <a class="s-btn s-btn-primary" @click="cancel">確認</a>
    </template>
  </div>
</template>

<script>
  import moment from 'moment'
  import Status from '@/components/Page/Liff/GroupBuying/Status'
  import fetchGroupBuying from '@/mixins/liff/groupBuying/fetchGroupBuying'
  import liff from '@line/liff'
  import { mapActions } from 'vuex'

  export default {
    mixins: [fetchGroupBuying],
    components: { Status },
    data: () => ({
      order: {},
    }),
    computed: {
      orderQuantity() {
        return this.order?.quantity;
      },
      endAt() {
        return moment(this.groupBuying.end_at).format("YYYY/MM/DD");
      },
      paidPrice() {
        let sku = this.groupBuying.groupbuying_sku;
        if (sku.is_promotion) {
          // 之後要封裝成一個可以共用的計算器
          if (sku.promotion_config.type === 'combination') {
            let remainder = this.orderQuantity % sku.promotion_config.config.quantity;
            let multiplier = (this.orderQuantity - remainder) / sku.promotion_config.config.quantity;
            return multiplier * sku.promotion_config.config.price + remainder * sku.price;
          }
        }
        return sku.price * this.orderQuantity;
      },
    },
    created() {
      this.fetchOrder();
    },
    methods: {
      ...mapActions({
        getOrder: 'liffGroupBuying/getOrder',
      }),
      async fetchOrder () {
        const data  = await this.getOrder(
          { id: this.$route.params.order_id }
          )
        this.order = data
      },
      async cancel() {
        await new Promise(() => liff.closeWindow())
        window.close();
      },
    }
  }
</script>

<style>
.product-card {
  background-color: rgb(239, 239, 239);
  padding: 2rem;
}
</style>
