<template>
  <div>
    <pre>{{ liffInfo }}</pre>
    <pre>{{ token }}</pre>
    <pre>{{ user }}</pre>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState({
        liffInfo: (state) => state.liffGroupBuying.liffInfo,
        token: (state) => state.liffGroupBuying.token,
        user: (state) => state.liffGroupBuying.user,
      }),
    },
  }
</script>
