<template>
  <div>
    <p v-if="fetching"><Loading /></p>
    <template v-else>
      <div class="s-mb-6 s-space-y-3 text-center">
        <Status :class="statusClass" :icon="statusIcon" :message="statusMessage" />
        <p v-html="statusInfo"></p>
      </div>
      <div class="s-space-y-8 s-mb-8" v-if="displayProduct">
        <DescriptionList title="商品名稱">{{ display.productTitle }}</DescriptionList>
        <DescriptionList title="團購編號">{{ display.groupbuyingNumber }}</DescriptionList>
      </div>
      <a class="s-btn s-btn-primary" @click="cancel">確認</a>
    </template>
  </div>
</template>

<script>
  import Loading from '@/components/Page/Liff/Shared/Loading'
  import Status from '@/components/Page/Liff/GroupBuying/Status'
  import DescriptionList from '@/components/Page/Liff/GroupBuying/DescriptionList'
  import liff from '@line/liff'
  import { mapState, mapActions } from 'vuex'

  const STATUS_CLOSED = 'closed'
  const STATUS_SUSPENDED = 'suspended'
  const STATUS_FAILED = 'failed'
  const STATUS_NOT_FOUND = 'not-found'

  export default {
    components: { Loading, Status, DescriptionList },
    props: ['groupBuying'],

    data: () => ({
      display: {
        productTitle: '',
        groupbuyingNumber: '',
      },
      fetching: false,
    }),

    mounted() {
      this.setDisplay()
    },

    computed: {
      ...mapState({
        liffInfo: (state) => state.liffGroupBuying.liffInfo,
      }),
      status () {
        return this.$route.params.status
      },
      statusClass () {
        if (this.status === STATUS_CLOSED) return 'g-text-secondary'
        if (this.status === STATUS_SUSPENDED) return 'g-text-gray'
        if (this.status === STATUS_FAILED) return 'g-text-gray'
        if (this.status === STATUS_NOT_FOUND) return 'g-text-gray'
        return ''
      },
      statusIcon () {
        if (this.status === STATUS_CLOSED) return 'clock'
        if (this.status === STATUS_SUSPENDED) return 'forbidden'
        if (this.status === STATUS_FAILED) return 'error'
        if (this.status === STATUS_NOT_FOUND) return 'error'
        return ''
      },
      statusMessage () {
        if (this.status === STATUS_CLOSED) return '此團已結單'
        if (this.status === STATUS_SUSPENDED) return '此團已暫停收單'
        if (this.status === STATUS_FAILED) return '下單出現錯誤'
        if (this.status === STATUS_NOT_FOUND) return '此團不存在'
        return ''
      },
      displayProduct () {
        return this.status !== STATUS_NOT_FOUND;
      },
      statusInfo () {
        if (this.groupBuying && this.groupBuying.type === 3 && this.status === STATUS_CLOSED) {
          return "尚未跟上本次團購，可於群組內告知小編<br>因團購商品為不定時上架，將依情況開團呦";
        } else {
          return '請與客服人員聯繫<br>' + (this.liffInfo.liff_extra?.service_phone ?? '');
        }
      },
    },
    methods: {
      ...mapActions({
        getGroupBuying: 'liffGroupBuying/getGroupBuying',
      }),
      async cancel() {
        await new Promise(() => liff.closeWindow())
        window.close();
      },
      async setDisplay() {
        this.fetching = true
        let groupBuying = this.groupBuying
        if (!groupBuying) {
          groupBuying = await this.getGroupBuying({ id: this.$route.params.id })
        }

        this.display = {
          'productTitle': groupBuying?.groupbuying_product?.title ?? '',
          'groupbuyingNumber': groupBuying?.groupbuying_number ?? '',
        }
        this.fetching = false
      }
    },
  }
</script>
