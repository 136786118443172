<template>
  <div class="s-layout">
    <div class="s-container text-center">
      <template v-if="!isLoading && hitAgain">
        <h2 class="s-text-xl s-text-gray-darker s-font-semibold s-mb-2">您已領過此券囉！</h2>
        <div class="s-mb-6 s-space-y-3">
          <p>如需確認此券使用狀態，請至POYA寶雅/寶家POYA HOME官方帳號優惠券匣查詢</p>
        </div>
        <a class="s-btn s-btn-primary" @click="cancel">確認</a>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      redirectUri: '',
      hitAgain: false,
      isLoading: true,
    }
  },
  async created() {
    await this.redirect()
    if (!this.isLoading) {
      if (!this.hitAgain) {
        window.location.href = this.redirectUri
      } else {
        document.title = "您已領過此券囉！";
      }
    }
  },
  methods: {
    async redirect() {
      let { hashid: hashid } = this.$route.query
      const orgCode = this.$route.params.orgCode;
      const url =
        process.env.VUE_APP_API_BASE_URL +
        `/${orgCode}/liff/redirectTo?hashid=${hashid}`;

      await axios.get(url)
        .then(({ data }) => {
          this.hitAgain = data.hit_again
          this.redirectUri = data.redirect_url
        }).finally(()=>{
          this.isLoading = false
        });
    },
    cancel() {
      window.location.href = this.redirectUri
    }
  }
}
</script>


<style>
  @import "../../../assets/css/liff/main.css";

  .s-layout,
  .s-layout *,
  .s-layout *:before,
  .s-layout *:after {
    --s-primary: #e95383, #f6c65a;
    --s-secondary: #de006f;
    --s-warning: #ed6c00;
    --s-danger: #fe0000;
    --s-gray-darker: #363636;
    --s-gray-dark: #595959;
    --s-gray: #909399;
    --s-gray-light: #c0c4cc;
    --s-gray-lighter: #ecedef;
  }

  .s-layout {
    color: var(--s-gray-dark);
    background-color: var(--s-white);
  }

  .s-container {
    padding: 1.5rem 1rem;
  }
</style>