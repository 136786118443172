<template>
  <div>
    <p v-if="fetching">Loading...</p>
    <template v-else>
      <div class="s-mb-6 s-space-y-3 text-center">
        <Status class="s-text-secondary" icon="check" message="您已完成下單" />
        <p>感謝您的關注</p>
      </div>
      <DescriptionList class="s-mb-8" title="商品名稱">{{ product.title }}</DescriptionList>
      <div class="s-space-y-8 s-pb-8 s-mb-5 s-border-b s-border-gray-lighter">
        <div class="s-flex">
          <DescriptionList class="s-flex-1" title="款式">{{ spec.sku_name }}</DescriptionList>
          <DescriptionList class="s-flex-1" title="購買數量">{{ data.quantity }} 組</DescriptionList>
        </div>
        <DescriptionList title="下單金額">{{ $currency(data.total_price) }}</DescriptionList>
        <div class="s-flex">
          <DescriptionList class="s-flex-1" title="團購 ID">{{ groupBuying.groupbuying_number }}</DescriptionList>
          <DescriptionList class="s-flex-1" title="訂單 ID">{{ data.order_number }}</DescriptionList>
        </div>
      </div>
      <template v-if="statusCategory !== STATUS_GOING">
        <p class="s-mb-5 s-text-secondary">&darr; 請持續關注 LINE 確認此團進度</p>
        <a class="s-btn s-btn-secondary" href="#">前往 LINE 帳號</a>
      </template>
      <template v-else>
        <a
          class="s-btn s-btn-primary s-mb-4"
          :href="`${apiBaseURL}/goto-ec/${data.id}`"
        >
          前往付款
        </a>
        <router-link
          class="s-btn s-btn-secondary s-mb-8"
          :to="{
             name: 'LiffGroupBuyingShow',
             params: { id: groupBuying.id },
             query: { modify: null },
          }"
        >
          編輯下單數量
        </router-link>
        <p class="s-text-center">
          <a class="s-link s-link-secondary" href="#" @click.prevent="$refs.confirm.open()">
            取消下單
          </a>
        </p>
        <Modal ref="confirm" title="您是否要取消訂單？">
          您是否要取消訂單？
          <template #actions="{ close }">
            <div class="s-flex s-space-x-3">
              <button class="s-btn s-btn-secondary" type="button" @click="cancelOrder">取消訂單</button>
              <button class="s-btn s-btn-primary" type="button" @click="close">保留訂單</button>
            </div>
          </template>
        </Modal>
      </template>
    </template>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import Status from '@/components/Page/Liff/GroupBuying/Status'
  import DescriptionList from '@/components/Page/Liff/GroupBuying/DescriptionList'
  import Modal from '@/components/Page/Liff/Shared/Modal'
  import withFetcher from '@/mixins/liff/withFetcher'
  import order from '@/mixins/liff/groupBuying/order'

  export default {
    mixins: [withFetcher, order],
    components: { Status, DescriptionList, Modal },
    inject: ['$alert'],
    data: () => ({
      data: {},
    }),
    computed: {
      ...mapState({
        apiBaseURL: (state) => state.liffGroupBuying.apiBaseURL,
      }),
    },
    methods: {
      ...mapActions({
        getOrder: 'liffGroupBuying/getOrder',
        cancelOrderAction: 'liffGroupBuying/cancelOrder',
      }),
      async fetch () {
        this.data = await this.getOrder({ id: this.$route.params.id })
      },
      async cancelOrder () {
        try {
          await this.cancelOrderAction({ id: this.$route.params.id })
        } catch({ message }) {
          return this.$alert(message)
        }

        this.$router.push({
          name: 'LiffGroupBuyingOrdersCanceled',
          params: { id: this.groupBuying.id },
        })
      },
    },
  }
</script>
