<template>
  <div>
    <Status class="s-text-secondary s-mb-6" icon="check" message="綁定完成！" />
    <p class="s-mb-6 s-text-center">{{ message }}</p>
    <button class="s-btn s-btn-secondary" type="button" @click="next">{{ buttonText }}</button>
  </div>
</template>

<script>
  import Status from '@/components/Page/Liff/GroupBuying/Status'
  import IntendedRoute from '@/utils/liff/IntendedRoute'

  const ROUTE_ORDERS = 'LiffGroupBuyingOrders'
  const ROUTE_GROUPBUYING_SHOW = 'LiffGroupBuyingShow'

  export default {
    components: { Status },
    data: () => ({
      timer: null,
    }),
    computed: {
      intended () {
        return IntendedRoute.get({ name: ROUTE_ORDERS })
      },
      message () {
        if (this.intended.name === ROUTE_GROUPBUYING_SHOW) return '稍後將自動跳轉至下單頁面'
        return '稍後將自動跳轉至訂單查詢頁面'
      },
      buttonText () {
        if (this.intended.name === ROUTE_GROUPBUYING_SHOW) return '前往下單'
        return '前往訂單查詢'
      },
    },
    methods: {
      next () {
        IntendedRoute.clear()

        this.$router.push(this.intended)
      },
    },
    mounted () {
      this.timer = window.setTimeout(() => this.next(), 5000)
    },
    beforeDestroy () {
      window.clearTimeout(this.timer)
    },
  }
</script>
