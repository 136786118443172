<template>
  <button
    class="s-btn s-btn-secondary s-btn-sm s-w-auto"
    type="button"
    @click="sendVerifyCode"
    :disabled="waiting || !mobildNumberIsValid"
  >
    <template v-if="timer">{{ counter }} 秒</template>
    <template v-else>
      <slot>發送驗證碼</slot>
    </template>
  </button>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    inject: ['$alert'],
    props: {
      mobileNumber: {
        type: String,
        required: true,
      },
      mobildNumberIsValid: {
        type: Boolean,
      },
    },
    data: () => ({
      waiting: false,
      counter: 90,
      timer: null,
    }),
    beforeDestroy () {
      this.clearTimer()
    },
    methods: {
      ...mapActions({
        sendVerifyCodeAction: 'liffGroupBuying/sendVerifyCode',
      }),
      async sendVerifyCode () {
        try {
          this.waiting = true
          this.counter = 90
          await this.sendVerifyCodeAction({ mobileNumber: this.mobileNumber })
          this.startTimer()
        } catch ({ message }) {
          this.waiting = false
          this.$alert(message)
        }
      },
      startTimer () {
        this.timer = window.setInterval(() => {
          this.counter--

          if (this.counter > 0) {
            return
          }

          this.waiting = false
          this.clearTimer()
        }, 1000)
      },
      clearTimer () {
        if (!this.timer) {
          return
        }

        window.clearInterval(this.timer)
        this.timer = null
      },
    },
  }
</script>
