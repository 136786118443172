<template>
  <div>
    <PageTitle>請綁定 POYA Pay 會員</PageTitle>
    <Form :action="submit" :after-action="afterSubmit" v-slot="{ busy }">
      <div class="s-space-y-3 s-mb-10">
        <div class="s-flex s-items-center s-space-x-2">
          <Input
            class="s-flex-1"
            label="手機號碼"
            type="text"
            inputmode="tel"
            name="mobileNumber"
            placeholder="0987654321"
            pattern="\d*"
            maxlength="10"
            v-model="mobileNumber"
            required
          />
          <SendVerifyCode
            :mobile-number="mobileNumber"
            :mobild-number-is-valid="mobildNumberIsValid"
          />
        </div>
        <Input
          label="簡訊驗證碼"
          type="text"
          inputmode="numeric"
          name="verifyCode"
          placeholder="123456"
          pattern="\d*"
          maxlength="6"
          v-model="verifyCode"
          required
        />
      </div>
      <button class="s-btn s-btn-primary" type="submit" :disabled="!formIsValid || busy">
        下一步
      </button>
    </Form>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import PageTitle from '@/components/Page/Liff/GroupBuying/PageTitle'
  import Input from '@/components/Page/Liff/Form/Input'
  import SendVerifyCode from '@/components/Page/Liff/GroupBuying/SendVerifyCode'
  import Form from '@/components/Page/Liff/Form/Form'

  export default {
    components: { PageTitle, Input, SendVerifyCode, Form },
    data: () => ({
      mobileNumber: '',
      verifyCode: '',
      isMember: false,
    }),
    computed: {
      mobildNumberIsValid () {
        return /\d{10}/.test(this.mobileNumber)
      },
      verifyCodeIsValid () {
        return /\d{6}/.test(this.verifyCode)
      },
      formIsValid () {
        return this.mobildNumberIsValid && this.verifyCodeIsValid
      },
    },
    methods: {
      ...mapActions({
        checkMemberState: 'liffGroupBuying/checkMemberState',
      }),
      async submit () {
        this.isMember = await this.checkMemberState({
          mobileNumber: this.mobileNumber,
          verifyCode: this.verifyCode,
        })
      },
      afterSubmit () {
        if (this.isMember) {
          return this.$router.push({ name: 'LiffGroupBuyingAuthLogin', query: { mobile: this.mobileNumber } })
        }

        this.$router.push({ name: 'LiffGroupBuyingAuthRegister' })
      },
    },
  }
</script>
