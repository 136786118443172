<template>
  <div>
    <PageTitle>您尚未註冊 POYA Buy 帳號</PageTitle>
    <p class="s-mb-10">請前往 POYA Buy 完成註冊後，回到 LINE 完成會員綁定即可下單。</p>
    <a class="s-btn s-btn-primary" href="https://www.poyabuy.com.tw/Login/HttpLoginRelayToHttps/?rt=https%3A%2F%2Fwww.poyabuy.com.tw%2F&reason=notlogin">前往註冊</a>
  </div>
</template>

<script>
  import PageTitle from '@/components/Page/Liff/GroupBuying/PageTitle'

  export default {
    components: { PageTitle },
  }
</script>
