<template>
  <div>
    <p v-if="fetching">Loading...</p>
    <template v-else>
      <Status class="s-mb-6 s-text-gray" icon="check" message="已為您取消訂單" />
      <div class="s-space-y-8 s-pb-8 s-mb-5 s-border-b s-border-gray-lighter">
        <DescriptionList title="商品名稱">{{ product.title }}</DescriptionList>
        <DescriptionList title="款式">{{ spec.sku_name }}</DescriptionList>
        <DescriptionList title="團購 ID">{{ groupBuying.groupbuying_number }}</DescriptionList>
      </div>
      <router-link
        class="s-btn s-btn-secondary"
        :to="{ name: 'LiffGroupBuyingShow', params: { id: groupBuying.id } }"
      >
        重新下單
      </router-link>
    </template>
  </div>
</template>

<script>
  import Status from '@/components/Page/Liff/GroupBuying/Status'
  import DescriptionList from '@/components/Page/Liff/GroupBuying/DescriptionList'
  import fetchGroupBuying from '@/mixins/liff/groupBuying/fetchGroupBuying'

  export default {
    mixins: [fetchGroupBuying],
    components: { Status, DescriptionList },
  }
</script>
