<template>
  <div>
    <Status class="s-text-secondary s-mb-6" icon="check" message="密碼修改完成！" />
    <p class="s-mb-6 s-text-center">請重新登入會員</p>
    <router-link
      class="s-btn s-btn-secondary"
      :to="{ name: 'LiffGroupBuyingAuthLogin', query: $route.query }"
    >
      重新登入
    </router-link>
  </div>
</template>

<script>
  import Status from '@/components/Page/Liff/GroupBuying/Status'

  export default {
    components: { Status },
  }
</script>
