<template>
  <div class="s-order-item">
    <div
      class="s-order-item-image"
      :class="order.is_original_org ? 's-bg-secondary' : 's-bg-warning'"
      @click="showDetail()"
    >
      <div class="s-order-item-image-wrapper">
        <img :src="image" />
      </div>
      <p class="s-py-2 s-text-center">
        <img
          src="@/assets/images/liff/group-buying/poya.svg"
          v-if="order.is_original_org"
        />
        <img src="@/assets/images/liff/group-buying/poya-home.svg" v-else />
      </p>
    </div>
    <div class="s-order-item-info s-space-y-2 mt-2" @click="showDetail()">
      <p class="s-text-gray-darker s-text-xs s-whitespace-nowrap">
        訂購日 : <span v-if="order" >{{ createdDateTime }}</span>
      </p>
      <p class="s-text-gray-darker s-text-xs s-whitespace-nowrap">
        訂單號 : <span v-if="order">{{ order.outer_order_number }}</span>
      </p>
      <p class="s-text-gray-darker s-text-xs s-whitespace-nowrap" v-if="order && order.source_type === 3">
        取貨期限 : <span>{{ deadlineDate }}</span>
      </p>
      <p v-if="order" class="s-text-gray-darker s-text-xs s-whitespace-nowrap">
        {{ deliveryText }}
      </p>
      <p class="s-font-bold s-text-xs">
        <span class="s-text-gray-darker s-mr-2">共 {{ quantity }} 件</span>
        <span class="s-text-secondary">{{ $currency(order.total) }}</span>
        <span class="ml-2 s-orders-btn">查看</span>
      </p>
    </div>
    <div class="s-order-item-tag s-flex-col s-justify-between">
      <div>
        <a
          class="s-tag s-mt-auto s-tag-secondary"
          v-if="order"
          >{{ order.status_label }}</a
        >
      </div>
      <div @click.prevent="$refs.qrcode.open()" v-if="order.source_type === 3 && status === 'processing'">
        <!-- TODO: hardcode qrcode image  -->
        <img
          class="s-order-item-qrcode"
          src="@/assets/images/liff/group-buying/img-qrcode-mock.png"
          alt=""
          srcset=""
        />
      </div>
    </div>

    <Modal ref="qrcode" title="" noActions :hasHeader="false">
      <template>
        <div class="s-flex s-flex-center mb-3">
          <!-- TODO: hardcode qrcode  -->
          <qr-code :text="order.outer_order_number"/>
          <!-- <img src="@/assets/images/liff/group-buying/img-product-qrcode.png" /> -->
        </div>
        <div class="s-flex s-space-x-3 s-flex-center m-2">
          <div
            class="s-order-item-image"
            :class="order.is_original_org ? 's-bg-secondary' : 's-bg-warning'"
          >
            <div class="s-order-item-image-wrapper">
              <img :src="image" />
            </div>
            <p class="s-py-2 s-text-center">
              <img
                src="@/assets/images/liff/group-buying/poya.svg"
                v-if="order.is_original_org"
              />
              <img
                src="@/assets/images/liff/group-buying/poya-home.svg"
                v-else
              />
            </p>
          </div>
          <div class="s-order-item-info s-space-y-2 mt-2 text-left ml-2">
            <p class="s-text-gray-darker s-text-xs s-whitespace-nowrap">
              訂購日 : <span v-if="order">{{ createdDateTime }}</span>
            </p>
            <p class="s-text-gray-darker s-text-xs s-whitespace-nowrap">
              訂單號 : <span v-if="order">{{ order.outer_order_number }}</span>
            </p>
            <p class="s-font-bold s-text-xs">
              <span class="s-text-gray-darker s-mr-2"
                >共 {{ quantity }} 件</span
              >
              <span class="s-text-secondary">{{ $currency(order.total) }}</span>
            </p>
            <p class="s-text-gray-darker s-text-xs s-whitespace-nowrap">
              {{ deliveryText }}
            </p>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import order from "@/mixins/liff/groupBuying/order";
import Modal from "@/components/Page/Liff/Shared/Modal";

export default {
  mixins: [order],
  components: { Modal },
  inject: ["$currency"],
  props: {
    data: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    images() {
      return this.order.items[0]?.product?.images || [{}];
    },
    image() {
      return this.images[0].pic_url;
    },
    quantity() {
      if (this.order.items.length === 0) {
        return 0;
      }
      return this.order.items.map((el) => el.quantity).reduce((a, b) => a + b);
    },
    deliveryText() {
      const delType = {
        Home: "宅配取貨",
        LocationPickup: "門市自取 : ",
      };
      let delTypeText = delType[this.order.delivery_type];
      if (!this.order.delivery_type) {
        delTypeText = '取貨方式 : '
      }
      return delTypeText + (this.order.branch_name ?? this.order.outer_branch_name ?? '');
    },
    createdDateTime() {
      return moment(this.order.created_at).format("YYYY/MM/DD");
    },
    deadlineDate() {
      if (this.order.deadline_at) {
        return moment(this.order.deadline_at).format("YYYY/MM/DD");
      } else {
        return "無";
      }
    },
  },
  methods: {
    showDetail() {
      this.$router.push({
        name: "LiffGroupBuyingOrdersV2Detail",
        params: { id: this.data.id, status: this.status },
      });
    },
  },
};
</script>

<style scoped>
.s-order-item {
  padding: 0.75rem 0;
  border-bottom: solid 1px var(--s-gray-lighter);
  display: flex;
  min-height: 8rem;
}

.s-order-item-image {
  flex-shrink: 0;
  margin-right: 0.5rem;
  border-radius: 0.375rem;
  font-size: 0;
  align-self: flex-start;
}

.s-order-item-image-wrapper {
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
  background-color: var(--s-white);
}

.s-order-item-qrcode {
  width: 3rem;
  height: 3rem;
  margin: 0.25rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
  background-color: var(--s-white);
}

.s-order-item-qrcode img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.s-order-item-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.s-order-item-info {
  margin-right: 0.5rem;
  width: 45%;
}

.s-order-item-tag {
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4.5rem;
}

.s-tag {
  text-align: center;
}

.s-orders-btn {
  color: var(--s-secondary);
  border: 1px solid var(--s-secondary);
  text-decoration: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
  padding: 2px 6px;
}
</style>
