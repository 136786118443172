<template>
  <div v-if="!oaIsFollowed">
    <PageTitle>請加入好友</PageTitle>
    <p class="s-mb-10">
      將 {{ liffInfo.liff_og_title }} 加為好友，才可開始團購、管理訂單及追蹤商品。<br/>
      <br/>
      點擊【加入好友】按鈕並綁定會員，一起加入團購吧！
    </p>
    <a class="s-btn s-btn-primary" :href="`https://line.me/R/ti/p/@${oaId}`">加入好友</a>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import IntendedRoute from '@/utils/liff/IntendedRoute'
  import PageTitle from '@/components/Page/Liff/GroupBuying/PageTitle'

  const ROUTE_ORDERS = 'LiffGroupBuyingOrders'

  export default {
    components: { PageTitle },
    computed: {
      ...mapGetters({
        liffInfo: 'liffGroupBuying/liffInfo',
        oaId: 'liffGroupBuying/oaId',
        oaIsFollowed: 'liffGroupBuying/oaIsFollowed',
      }),
    },
    async created () {
      if (!this.oaIsFollowed) {
        return
      }

      const intended = IntendedRoute.get({ name: ROUTE_ORDERS })

      IntendedRoute.clear()

      await this.$router.push(intended)
    },
  }
</script>
