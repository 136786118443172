<template>
  <div>
    <PageTitle>UI</PageTitle>
    <div class="s-space-y-3">
      <div class="s-text-caption">caption</div>
      <div class="s-text-xl">xl</div>
      <div class="s-text-base">base</div>
      <div class="s-text-sm">sm</div>
    </div>
    <hr>
    <div class="s-space-y-3">
      <div class="s-font-bold">bold</div>
      <div class="s-font-semibold">semibold</div>
      <div class="s-font-medium">medium</div>
      <div class="s-font-normal">normal</div>
    </div>
    <hr>
    <div class="s-space-y-3">
      <div class="s-text-secondary">secondary</div>
      <div class="s-text-warning">warning</div>
      <div class="s-text-danger">danger</div>
      <div class="s-text-black">black</div>
      <div class="s-text-gray-darker">gray-darker</div>
      <div class="s-text-gray-dark">gray-dark</div>
      <div class="s-text-gray">gray</div>
      <div class="s-text-gray-light">gray-light</div>
      <div class="s-text-gray-lighter">gray-lighter</div>
      <div class="s-text-white">white</div>
    </div>
    <hr>
    <div class="s-space-y-3">
      <Input label="default" name="default" placeholder="default" v-model="text" />
      <Input label="error" name="error" placeholder="error" error="error" v-model="text" />
      <Input label="help" name="help" placeholder="help" help="help" v-model="text" />
      <Input label="disabled" name="disabled" placeholder="disabled" v-model="text" disabled />
    </div>
    <hr>
    <div class="s-space-y-3">
      <button class="s-btn s-btn-primary" type="button">primary</button>
      <button class="s-btn s-btn-primary" type="button" disabled>primary</button>
      <button class="s-btn s-btn-secondary" type="button">second</button>
      <button class="s-btn s-btn-secondary" type="button" disabled>second</button>
      <button class="s-btn s-btn-secondary s-btn-sm" type="button">second sm</button>
      <button class="s-btn s-btn-secondary s-btn-sm" type="button" disabled>second sm</button>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/Page/Liff/GroupBuying/PageTitle'
  import Input from '@/components/Page/Liff/Form/Input'

  export default {
    components: { PageTitle, Input },
    data: () => ({
      text: '',
    }),
  }
</script>

<style scoped>
  hr {
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
</style>
