<template>
  <div>
    <p v-if="fetching"><Loading /></p>
    <Form :action="check" v-slot="{ busy }" ref="form" v-else>
      <div class="s-mb-2" v-if="coverImage">
        <img class="s-rounded-lg s-w-full" :src="coverImage">
      </div>
      <h2 class="s-text-xl s-text-gray-darker s-font-semibold s-mb-2">{{ product.title }}</h2>
      <div v-if="groupBuying.type !== 3 && groupBuying.type !== 4">
        <dl class="s-flex">
          <dt>商品單價：</dt>
          <dd>{{ spec.price }} 元</dd>
        </dl>
        <dl class="s-flex">
          <dt>結單時間：</dt>
          <dd>{{ endAt }}</dd>
        </dl>
        <dl class="s-flex">
          <dt>取貨方式：</dt>
          <dd>{{ deliveryMethods }}</dd>
        </dl>
        <dl class="s-flex">
          <dt>付款方式：</dt>
          <dd>下單後立即付款</dd>
        </dl>
        <dl class="s-flex">
          <dt>出貨日期：</dt>
          <dd>{{ groupBuying.delivery_shipping_day }}</dd>
        </dl>
      </div>
      <div v-else><!--- Type = 3 的門市庫存品類型團購 --->
        <dl class="s-flex">
          <dt>商品原價：</dt>
          <dd>{{ groupBuying.groupbuying_sku.original_price }} 元</dd>
        </dl>
        <dl class="s-flex">
          <dt>商品售價：</dt>
          <dd v-if="groupBuying.groupbuying_sku.is_promotion">{{ groupBuying.groupbuying_sku.package_type }}</dd>
          <dd v-else>{{ groupBuying.groupbuying_sku.price }} 元</dd>
        </dl>
        <dl class="s-flex">
          <dt>取貨方式：</dt>
          <dd>門市自取</dd>
        </dl>
        <dl class="s-flex">
          <dt>付款方式：</dt>
          <dd>下單後門市付款</dd>
        </dl>
        <dl class="s-flex">
          <dt>取貨期限：</dt>
          <dd>{{ branchEndAt }}</dd>
        </dl>
      </div>
      <div class="s-mb-2" v-html="groupBuying.extra_message" :style="{color: groupBuying.extra_message_color}" />
      <div class="s-pb-4 s-mb-4 s-border-b s-border-gray-lighter" />
      <h3 class="s-text-gray-darker s-font-semibold s-mb-2">商品說明</h3>
      <div class="s-mb-2" v-html="description" />
      <div class="s-mb-2" v-if="productImages.length">
        <img class="s-w-full" :src="image" :key="i" v-for="(image, i) in productImages">
      </div>
      <div>
        <template v-if="(groupBuying.type == groupBuyingType3 || groupBuying.type == 4 ) && groupBuying.extra_images">
          <img v-for="image in groupBuying.extra_images" :key="image.url" :src="image.url" class="s-w-full">
          <!-- <span>販售業者資訊：</span><br />
          <span>【醫療器材】</span>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap">販售業者資料許可證字號：</dt>
            <dd>南市醫器販字第MD6205011041號</dd>
          </dl>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap">公司名稱：</dt>
            <dd>寶雅國際股份有限公司</dd>
          </dl>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap">公司地址：</dt>
            <dd>台南市中西區民族路3段74號</dd>
          </dl>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap">公司諮詢專線：</dt>
            <dd>0800-033-178</dd>
          </dl>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap">公司服務時間：</dt>
            <dd>週一至週五 8:30~12:30 / 13:30~17:30（不包含國定假日）</dd>
          </dl>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap s-mr-1">※</dt>
            <dd>消費者使用前應詳閱醫療器材說明書</dd>
          </dl>
          <br />
          <span>【食品】</span>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap">食品業者登錄字號：</dt>
            <dd>D-197151664-00000-0</dd>
          </dl>
          <br />
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap s-mr-1">(*)</dt>
            <dd>有效日期說明:以消費者收受日起算，至少距有效日期前30日以上；如商品名稱/商品敘述中標示短效或另有說明規則商品文案者，依該規則為準。</dd>
          </dl>
          <br />
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap s-mr-1">(*)</dt>
            <dd>提醒您:</dd>
          </dl>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap s-mr-1">(1)</dt>
            <dd>產品顏色可能會因網頁呈現與拍攝關係產生色差，圖片僅供參考，商品依實際供貨樣式為準。</dd>
          </dl>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap s-mr-1">(2)</dt>
            <dd>若商品收件時發現商品瑕疵，請立即拍照並通知客服人員，未即刻告知容易造成狀況難以判斷而影響退貨權益。</dd>
          </dl>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap s-mr-1">(3)</dt>
            <dd>生鮮食品不適用於消費者保護法第19條，並不享有7天鑑賞期，因冷藏食品或生鮮商品屬消耗性商品。</dd>
          </dl>
          <dl class="s-flex">
            <dt class="s-whitespace-nowrap s-mr-1">※</dt>
            <dd>商品屬性特殊或有保存期限等問題，除商品本身瑕疵或運送過程而造成的損毀，非商品瑕疵恕無法辦理退換貨。</dd>
          </dl>
          <br /> -->
        </template>
        <dl class="s-flex">
          <dt class="s-whitespace-nowrap">跟團群組：</dt>
          <dd>{{ group.name }}</dd>
        </dl>
        <dl class="s-flex">
          <dt class="s-whitespace-nowrap">團購編號：</dt>
          <dd>{{ groupBuying.groupbuying_number }}</dd>
        </dl>
      </div>
      <div class="s-product-form">
        <Counter
          class="s-mb-4"
          label="下單數量"
          name="counter"
          :min="min"
          :max="max"
          :step="basis"
          :help="`下單數量最多 ${max} 個，最少 ${min} 個`"
          help-class="s-text-danger"
          v-model="quantity"
          :disabled="isOutOfStock"
        />
        <div class="s-flex s-items-center">
          <dl class="s-flex-shrink-0 s-flex s-items-center s-mr-2">
            <dt class="s-mr-2">金額</dt>
            <dd class="s-text-xl s-text-secondary s-price">$ {{ price }}</dd>
          </dl>
          <button class="s-btn s-btn-primary" type="submit" :disabled="!product.id || busy || isOutOfStock">
            {{ isOutOfStock ? '已售完' : '確定下單' }}
          </button>
        </div>
      </div>
      <Modal ref="confirm" title="是否確認下單？">
        提醒您，因您已有{{blacklistCount}}次未取貨完成紀錄，<br>
        如未在期限內完成取件，此帳號將暫停下單功能。
        <template #actions="{ close }">
          <div class="s-flex s-space-x-3">
            <button class="s-btn s-btn-secondary" type="button" @click="close">取消</button>
            <button class="s-btn s-btn-primary" type="button" @click="submit">繼續下單</button>
          </div>
        </template>
      </Modal>

      <Modal ref="blocked" title="無法下單">
        很抱歉！因此帳號已達4次未取貨紀錄，<br>
        故已暫停下單功能。<br><br>
        如有下單需求，請聯繫群組小編，謝謝！
        <template #actions="{ close }">
          <div class="s-flex s-space-x-3">
            <button class="s-btn s-btn-primary" type="button" @click="close">確認</button>
          </div>
        </template>
      </Modal>
    </Form>
  </div>
</template>

<script>
  import moment from 'moment'
  // import liff from '@line/liff'
  import { mapActions, mapState } from 'vuex'
  import Modal from '@/components/Page/Liff/Shared/Modal'
  import Loading from '@/components/Page/Liff/Shared/Loading'
  import Counter from '@/components/Page/Liff/Form/Counter'
  import Form from '@/components/Page/Liff/Form/Form'
  import fetchGroupBuying from '@/mixins/liff/groupBuying/fetchGroupBuying'

  export default {
    mixins: [fetchGroupBuying],
    components: { Loading, Counter, Form, Modal },
    inject: ['$alert', '$user', '$error'],
    data: () => ({
      quantity: 1,
      selectedSpec: 0,
      blacklistCount: 0,
      groupBuyingType3: 3, // 門市開團（門市選品
    }),
    computed: {
      ...mapState({
        apiBaseURL: (state) => state.liffGroupBuying.apiBaseURL,
      }),
      specIndex () {
        return this.selectedSpec
      },
      options () {
        return this.specs.map((spec) => spec.sku_name)
      },
      min () {
        return this.groupBuying.once_quantity || 1
      },
      max () {
        return this.groupBuying.once_max_quantity || 10
      },
      basis () {
        return this.groupBuying.once_increase_quantity || 1
      },
      price () {
        let sku = this.groupBuying?.groupbuying_sku;
        if (sku?.is_promotion) {
          // 之後要封裝成一個可以共用的計算器
          if (sku.promotion_config.type === 'combination') {
            let remainder = this.quantity % sku.promotion_config.config.quantity;
            let multiplier = (this.quantity - remainder) / sku.promotion_config.config.quantity;
            return multiplier * sku.promotion_config.config.price + remainder * sku.price;
          }
        }

        if (!sku) {
          return 0
        }

        return sku.price * this.quantity;
      },
      order () {
        return this.groupBuying.customer_order || {}
      },
      isBlacklist () {
        return this.$user().isBlacklist()
      },
      isSuspended () {
        return this.statusCategory === this.STATUS_PENDING
      },
      isClosed () {
        return this.statusCategory !== this.STATUS_GOING && !this.isSuspended
      },
      endAt () {
        return moment(this.groupBuying.end_at).format("YYYY/MM/DD HH:mm");
      },
      branchEndAt() {
        return moment(this.groupBuying.end_at).format("YYYY/MM/DD");
      },
      description () {
        const description = this.product.description || ''

        return description.replace(/\n/g, '<br />')
      },
      deliveryMethods () {
        const methods = this.groupBuying.delivery_label || []

        return methods.join('、')
      },
      isOutOfStock() {
        return this.groupBuying.max_quantity - this.groupBuying.current_quantity <= 0;
      },
    },
    methods: {
      ...mapActions({
        createOrder: 'liffGroupBuying/createOrder',
        updateOrder: 'liffGroupBuying/updateOrder',
        setBlacklistRead: 'liffGroupBuying/setBlacklistRead',
        fetchUser: 'liffGroupBuying/fetchUser',
      }),
      async fetch () {
        try {
          this.groupBuying = await this.getGroupBuying({ id: this.$route.params.id })
          this.quantity = this.min
        } catch (error) {
          return this.$router.push({
            name: 'LiffGroupBuyingStatus',
            params: { status: 'not-found' },
          })
        }

        if (this.isSuspended) {
          return this.$router.replace({
            name: 'LiffGroupBuyingStatus',
            params: { status: 'suspended', groupBuying: this.groupBuying },
          })
        }

        if (this.isClosed) {
          return this.$router.replace({
            name: 'LiffGroupBuyingStatus',
            params: { status: 'closed', groupBuying: this.groupBuying },
          })
        }
      },
      async check() {
        let _this = this;
        if (this.groupBuying.type !== 3 && this.groupBuying.type !== 4) {
          let blacklist = this.$user().customer().blacklists.find(function (blacklist) {
            return blacklist.branch_id == _this.groupBuying.group.branch_id;
          });

          if (blacklist && blacklist.ban_at !== null) {
            this.$refs.blocked.open();
            return;
          }

          if (blacklist && blacklist.count >= 1 && blacklist.ban_at === null && blacklist.read_at === null) {
            this.blacklistCount = blacklist.count;
            this.$refs.confirm.open();
            await this.setBlacklistRead(this.groupBuying);
            await this.fetchUser();
            return;
          }
        }

        await this.submit();
      },
      async submit () {
        this.$refs.form.setBusy(true);
        this.$refs.confirm.close();

        try {
          const { id } = await this.createOrder({
            id: this.$route.params.id,
            specId: this.spec.id,
            quantity: this.quantity,
          })

          if (this.groupBuying.type === 3 || this.groupBuying.type === 4) {
            return this.$router.push({
              name: 'LiffGroupBuyingBranchOrderCompleted',
              params: { groupBuying: this.groupBuying, order_id: id },
            })
          } else {
            window.location = `${this.apiBaseURL}/goto-ec/${id}`
          }

          await new Promise(() => {})
        } catch ({ message }) {
          // TODO: correct the error message and flow
          await this.$alert(message, "貼心提醒")
          // await new Promise(() => liff.closeWindow())
        }
      },
    }
  }
</script>

<style scoped>
  .s-product-form {
    position: sticky;
    bottom: 0;
    background-color: var(--s-white);
    padding: 0.5rem 0;
    margin-bottom: -1.25rem;
  }

  .s-price {
    min-width: 5rem;
  }
</style>
