<template>
  <ul class="s-pagination">
    <li class="s-arrow" :disabled="current <= 1">
      <router-link :to="withPage(current - 1)">&lt;</router-link>
    </li>
    <li class="s-text-gray-darker my-auto mr-1">
      {{ current }}
    </li>
    <li class="s-text-gray-light my-auto">/</li>
    <li class="s-text-gray-darker my-auto ml-1">
      {{ last }}
    </li>
    <li class="s-arrow" :disabled="current >= last">
      <router-link :to="withPage(current + 1)">&gt;</router-link>
    </li>
  </ul>
</template>

<script>
  import pagination from '@/mixins/liff/pagination'

  export default {
    mixins: [pagination],
  }
</script>

<style scoped>
  .s-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .s-pagination li:not(:last-child) {
    margin-right: 0.5rem;
  }

  .s-pagination li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.125rem;
    color: var(--s-gray-dark);
    background-color: var(--s-gray-lighter);
  }

  .s-pagination li.active a {
    color: var(--s-white);
    background-color: var(--s-secondary);
    pointer-events: none;
  }

  .s-pagination li[disabled] a {
    color: var(--s-gray-light);
    pointer-events: none;
  }
</style>
